<template>
  <div
    class="p-4 client text-dark mycard position-relative"
    style="background-color: #f0e7e7"
  >
    <div class="position-absolute" style="top: -14px; right: -14px; z-index: 3">
      <el-popover
        placement="right"
        title="Help"
        width="300"
        trigger="hover"
        content="Please call us at +1-855-886-0505 for more details."
      >
        <div slot="reference">
          <InfoMark />
        </div>
      </el-popover>
    </div>
    <p>
      You have requested to <span style="font-weight: 500">{{ title }}</span> on
      <span style="font-weight: 500">{{ date }}</span
      >.
    </p>
    <p>
      Please contact our customer support at
      <span style="font-weight: 500">+1-855-886-0505 </span> or
      <span style="font-weight: 500">services@gophonebox.com</span>
      to make changes to your request.
    </p>
  </div>
</template>

<script>
import InfoMark from "./InfoMark";
export default {
  props: ["title", "date"],
  components: {
    InfoMark,
  },
};
</script>

<style lang="scss" scoped></style>
