import { render, staticRenderFns } from "./UpcomingChangesBar.vue?vue&type=template&id=afd9791c&scoped=true"
import script from "./UpcomingChangesBar.vue?vue&type=script&lang=js"
export * from "./UpcomingChangesBar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afd9791c",
  null
  
)

export default component.exports