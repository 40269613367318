<template>
  <div class="mx-3 mb-4" v-show="isPlanChangeRequested || isSuspensionRequested || isTerminationRequested">
    <h5 class="mb-3">Upcoming Changes</h5>
    <b-row>
      <!-- plan change status info -->
      <b-col md="6" sm="12" class="my-3" v-show="isPlanChangeRequested">
        <UpcomingChangesBar title="Change Your Plan" :date="planChangeDate" />
      </b-col>
      <!-- suspension status info -->
      <b-col md="6" sm="12" class="my-3" v-show="isSuspensionRequested">
        <UpcomingChangesBar
          title="Suspend Your Service"
          :date="suspensionDate"
        />
      </b-col>
      <!-- termination status info  -->
      <b-col md="6" sm="12" class="my-3" v-show="isTerminationRequested">
        <UpcomingChangesBar
          title="Terminate Your Service"
          :date="terminationDate"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UpcomingChangesBar from "../utils/UpcomingChangesBar";
export default {
  name: "UpcomingChangesStatus",
  components: {
    UpcomingChangesBar,
  },
  data() {
    return {
      isPlanChangeRequested: false,
      planChangeDate: "",
      isSuspensionRequested: false,
      suspensionDate: "",
      isTerminationRequested: false,
      terminationDate: "",
    };
  },
  mounted() {
    this.$axios
      .get("/Account/GetStatus")
      .then((response) => {
        let data = JSON.parse(response.data);
        if (data.PlanChangeStatus === "Requested") {
          this.isPlanChangeRequested = true;
          this.planChangeDate = data.PlanChangeEffectiveDate.split("T")[0];
        }
        if (data.SuspensionStatus === "Requested") {
          this.isSuspensionRequested = true;
          this.suspensionDate = data.SuspensionDate.split("T")[0];
        }
        if (data.SuspensionStatus === "Done") {
          this.suspensionDate = data.SuspensionDate.split("T")[0];
        }
        if (data.TerminationStatus === "Requested") {
          this.isTerminationRequested = true;
          this.terminationDate = data.TerminationDate.split("T")[0];
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss" scoped></style>
