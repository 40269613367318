<template>
  <div class="main">
    <i class="fas fa-info-circle"></i>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

.main {
  i {
  font-size: 25px;
  font-weight: 700;
  color: #DA3E26;
  }
}

.main:hover {
  cursor: pointer;
}

</style>