<template>
  <div
    class="p-4 client text-dark mycard position-relative"
    style="background-color: #f0e7e7"
  >
    <div class="position-absolute" style="top: -10px; right: -10px; z-index: 3">
      <el-popover
        placement="right"
        title="Help"
        width="300"
        trigger="hover"
        content="Please call us at +1-855-886-0505 for more details."
      >
        <div slot="reference">
          <InfoMark />
        </div>
      </el-popover>
    </div>
    <h6>
      Your plan is currently on <strong> {{ title }} </strong> from
      <span style="font-weight: 700"> {{ startDate }}</span
      >, your service will resume on
      <span style="font-weight: 700">{{ endDate }}.</span>
    </h6>
    <h6>
      Please contact our customer support at +1-855-886-0505 or
      services@gophonebox.com to make changes to your request.
    </h6>
  </div>
</template>

<script>
import InfoMark from "./InfoMark";
export default {
  props: ["title", "startDate", "endDate"],
  components: {
    InfoMark,
  },
};
</script>

<style lang="scss" scoped></style>
