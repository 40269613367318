<template>
  <div class="mx-3 mb-4" v-show="accountSuspended">
    <h5>Account Status</h5>
    <b-row>
      <b-col
        md="6"
        sm="12"
        class="my-3"
        v-if="accountSuspended && suspensionType === 'VC'"
      >
        <AccountStatusBar
          title="Vacation Plan"
          :startDate="vacationStartDate"
          :endDate="vacationEndDate"
        />
      </b-col>
      <b-col
        md="6"
        sm="12"
        class="my-3"
        v-if="accountSuspended && suspensionType === 'LS'"
      >
        <AccountStatusBar title="Lost/Stolen" :startDate="suspensionDate" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AccountStatusBar from "../utils/AccountStatusBar";
export default {
  name: "AccountStatus",
  components: {
    AccountStatusBar,
  },
  data() {
    return {
      accountSuspended: false,
      suspensionType: "",
      suspensionDate: "",
      vacationStartDate: "",
      vacationEndDate: "",
    };
  },
  mounted() {
    this.$axios
      .get("/Account/GetProfile")
      .then((response) => {
        let data = JSON.parse(response.data);
        this.accountSuspended = data.Suspended;
        this.suspensionType = data.SuspensionReason;
        if (data.SuspensionDate !== null) {
          this.suspensionDate = data.SuspensionDate.split("T")[0];
          // sometimes suspension date is on the 1st or 3rd when the 2nd falls on the weekend -> manually change day to 2nd
          this.vacationStartDate =
            data.SuspensionDate.split("-")[0] +
            "-" +
            data.SuspensionDate.split("-")[1] +
            "-02";
        }
        if (data.VacationEndDate !== null) {
          this.vacationEndDate = data.VacationEndDate;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss" scoped></style>
