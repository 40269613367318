<template>
  <div>
    <h5 class="p-3 mt-4">Plan Features</h5>
    <b-row class="px-3">
      <b-col sm="12" md="3" class="p-3">
        <a
          href="https://myaccount.gophonebox.com/other-plans"
          class="text-dark"
        >
          <div>
            <img
              src="@/assets/plans.png"
              class="border-radius-up"
              width="100%"
            />
            <p class="p-3 border-radius-down bg-white">
              <span style="font-weight:500;">
                Our Plans
              </span>
              <br />
              Find out more about our promotional plans that are best fit for
              you.
            </p>
          </div>
        </a>
      </b-col>
      <b-col sm="12" md="3" class="p-3">
        <a href="https://myaccount.gophonebox.com/profile" class="text-dark">
          <div>
            <img
              class="border-radius-up"
              src="@/assets/card.png"
              width="100%"
            />
            <p class="p-3 bg-white border-radius-down">
              <span style="font-weight:500;">
                Set up Auto-Payment
              </span>
              <br />
              Set up Auto-Payment for better convenience.
              <br /><br />
            </p>
          </div>
        </a>
      </b-col>
      <b-col sm="12" md="3" class="p-3">
        <a
          href="https://lega.gophonebox.com/en/OurPromotions"
          class="text-dark"
        >
          <div>
            <img
              src="@/assets/friends.png"
              width="100%"
              class="border-radius-up"
            />
            <p class="p-3 bg-white border-radius-down">
              <span style="font-weight:500;">
                Refer a Friend Reward
              </span>
              Refer a friend to receive one month free.
              <br /><br />
            </p>
          </div>
        </a>
      </b-col>
      <b-col sm="12" md="3" class="p-3">
        <a
          href="https://www.taxback.com/en/refund/simple-canada-tax-tb-lp-2020/?utm_ref=phonebox3"
          class="text-dark"
        >
          <div class="mycard">
            <img src="@/assets/tax.png" width="100%" class="border-radius-up" />
            <p class="p-3 border-radius-down bg-white">
              <span style="font-weight:500;">
                TaxBack Discount
              </span>
              <br />
              15% discount by signing up your PhoneBox's account through
              TaxBack.
            </p>
          </div>
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
