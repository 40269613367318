<template>
  <div class="home">
    <div class="mobileNav">Home</div>
    <b-container class="p-3">
      <AccountStatus />
      <UpcomingChangesStatus />
      <HomeMainSection />
      <LatestReleases />
    </b-container>
  </div>
</template>

<script>
import AccountStatus from "./HomeComponents/AccountStatus";
import UpcomingChangesStatus from "./HomeComponents/UpcomingChangesStatus";
import HomeMainSection from "./HomeComponents/HomeMainSection";
import LatestReleases from "./HomeComponents/LatestReleases";
export default {
  name: "Home",
  components: {
    AccountStatus,
    UpcomingChangesStatus,
    HomeMainSection,
    LatestReleases,
  },
  mounted() {
    this.$store.commit("setCurrentNav", "Home");
    this.$store.dispatch("initMultilinesData");
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding-top: 20px;
}

@media (max-width: 768px) {
  .home {
    padding-top: 0px;
  }
}
</style>
